import React, { useEffect, useState } from "react";
import BodyClassName from "react-body-classname";
import Layout from "../components/layout";
import Footer from "../components/footer";
import { connect } from "react-redux";
import { appUIPopupTooltipShow, appUIRegisterPageBack, appUIPopupTextoShow } from "../redux/app/actions";
import { authLocalRegisterValidateInit, authLocalRegisterInit } from "../redux/auth/actions";
import SEO from "../components/seo";
import Select from "react-select";
import { useIntl, navigate } from "gatsby-plugin-intl";
import Tools from "../classes/tools";

const CrearCuenta = ({
	appUIPopupTooltipShow,
	boot,
	registerPage,
	registerReady,
	appUIRegisterPageBack,
	authLocalRegisterValidateInit,
	authLocalRegisterInit,
	appUIPopupTextoShow,
	s,
}) => {
	const intl = useIntl();

	const [email, setEmail] = useState("");
	const [nombre, setNombre] = useState("");
	const [apellido, setApellido] = useState("");
	const [password, setPassword] = useState("");
	const [password2, setPassword2] = useState("");
	const [pais, setPais] = useState({});
	const [paises, setPaises] = useState([]);
	const [zona, setZona] = useState({});
	const [zonas, setZonas] = useState([]);
	const [localizacion, setLocalizacion] = useState({});
	const [localizaciones, setLocalizaciones] = useState([]);
	const [imagenSrc, setImagenSrc] = useState(null);
	const [descripcion, setDescripcion] = useState("");
	const [descripcionCaracteres, setDescripcionCaracteres] = useState(0);
	const [dni, setDNI] = useState("");
	const [telefono, setTelefono] = useState("");
	const [terminos, setTerminos] = useState(false);

	useEffect(() => {
		if (registerReady)
			authLocalRegisterInit(intl.locale, {
				email,
				nombre,
				apellido,
				password,
				password2,
				pais: pais.value || "",
				zona: zona.value || "",
				localizacion: localizacion.value || "",
				descripcion,
				imagen: imagenSrc,
				dni,
				telefono,
				terminos,
			});
	}, [registerReady]);

	useEffect(() => {
		if (boot.loaded) {
			const ps = [];
			boot.paises.forEach((p) => {
				ps.push({
					value: p.id,
					label: p.title,
				});
			});
			setPaises(ps);
		}
	}, [boot.loaded]);

	useEffect(() => {
		setDescripcionCaracteres(descripcion.length);
		if (descripcion.length > 200) {
			setDescripcion(descripcion.substr(0, 200));
		}
	}, [descripcion]);

	const handlePaisChange = (item) => {
		const zs = [];
		boot.paises.forEach((p) => {
			if (p.id === item.value) {
				setPais({
					value: p.id,
					label: p.title,
				});
				p.zonas.forEach((z) => {
					zs.push({
						value: z.id,
						label: z.title,
					});
				});
			}
		});
		setZona({});
		setZonas(zs);
		setLocalizacion({});
		setLocalizaciones([]);
	};

	const handleZonaChange = (item) => {
		const ls = [];
		boot.paises.forEach((p) => {
			p.zonas.forEach((z) => {
				if (z.id === item.value) {
					setZona({
						value: z.id,
						label: z.title,
					});
					z.localizaciones.forEach((l) => {
						ls.push({
							value: l.id,
							label: l.title,
						});
					});
				}
			});
		});
		setLocalizacion({});
		setLocalizaciones(ls);
	};

	const handleLocalizacionChange = (item) => {
		setLocalizacion(item);
	};

	var imagenReader = null;
	try {
		imagenReader = new FileReader(); // dentro de un try, sino webpack o quien sea no encuentra la clase
		imagenReader.addEventListener(
			"load",
			() => {
				const img = new Image();
				img.onload = () => {
					let w = 400;
					let h = 400;
					if (img.width > img.height) {
						h = w / (img.width / img.height);
					} else {
						w = h / (img.height / img.width);
					}
					const canvas = document.createElement("canvas");
					canvas.width = w;
					canvas.height = h;
					const ctx = canvas.getContext("2d");
					ctx.drawImage(img, 0, 0, w, h);
					setImagenSrc(canvas.toDataURL("image/jpeg"));
				};
				img.src = imagenReader.result;
			},
			false
		);
	} catch (error) {}

	const handleImagenChange = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const file = e.target.files[0];
			const allowedTypes = ["image/jpeg", "image/png"];
			if (allowedTypes.indexOf(file.type) > -1) {
				imagenReader.readAsDataURL(e.target.files[0]);
			}
		}
	};

	const back = () => {
		appUIRegisterPageBack();
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		switch (registerPage) {
			case 1:
				authLocalRegisterValidateInit(intl.locale, {
					nombre,
					apellido,
					email,
					password,
					password2,
				});
				break;
			case 2:
				authLocalRegisterValidateInit(intl.locale, {
					pais: pais.value || "",
					zona: zona.value || "",
					localizacion: localizacion.value || "",
				});
				break;
			case 3:
				authLocalRegisterValidateInit(intl.locale, {
					descripcion,
				});
				break;
			case 4:
				authLocalRegisterValidateInit(intl.locale, {
					dni,
					telefono,
					terminos,
				});
				break;
		}
	};

	const onHeaderGeoLocationChange = (data) => {
		navigate(
			`/anuncios/mode/geo/lat/${data.lat}/long/${data.long}/geo_descripcion/${data.descripcion}/orden/distancia`
		);
	};

	return (
		<>
			<BodyClassName className="register">
				<Layout onHeaderGeoLocationChange={onHeaderGeoLocationChange}>
					<SEO title={Tools.convertString(s.crearcuenta?.title)} />
					<main className="page form-page lightgray-bg-01">
						{/* STEP 01 */}
						{registerPage === 1 && (
							<div className="steps-wrapper">
								<article className="form-box box">
									<div className="wrapper">
										<h4 className="black-text-03 prequel-font">{Tools.convertString(s.crearcuenta?.lo_basico)}</h4>
										<ul className="steps-bullets">
											<li>
												<span className="lightgray-bg-03 white-text active"></span>
											</li>
											<li>
												<span className="lightgray-bg-03 white-text"></span>
											</li>
											<li>
												<span className="lightgray-bg-03 white-text"></span>
											</li>
											<li>
												<span className="lightgray-bg-03 white-text"></span>
											</li>
										</ul>
										<form onSubmit={handleSubmit}>
											<fieldset>
												<label className="black-text-01">
													{Tools.convertString(s.crearcuenta?.como_te_llamas)}
													<input
														className="input lightgray-bg-01 black-text-03"
														placeholder={Tools.convertString(s.crearcuenta?.nombre)}
														type="text"
														onChange={(e) => setNombre(e.target.value)}
														value={nombre}
													/>
												</label>
												<label className="black-text-01">
													{Tools.convertString(s.crearcuenta?.apellido)}
													<input
														className="input lightgray-bg-01 black-text-03"
														placeholder={Tools.convertString(s.crearcuenta?.apellido_placeholder)}
														type="text"
														onChange={(e) => setApellido(e.target.value)}
														value={apellido}
													/>
												</label>
												<label className="black-text-01">
													{Tools.convertString(s.crearcuenta?.email)}
													<input
														className="input lightgray-bg-01 black-text-03"
														placeholder={Tools.convertString(s.crearcuenta?.email_placeholder)}
														type="text"
														onChange={(e) => setEmail(e.target.value)}
														value={email}
													/>
												</label>
												<label className="black-text-01" htmlFor="password">
													{Tools.convertString(s.crearcuenta?.password)}
													<input
														className="input lightgray-bg-01 black-text-03"
														placeholder={Tools.convertString(s.crearcuenta?.password_placeholder)}
														type="password"
														onChange={(e) => setPassword(e.target.value)}
														value={password}
													/>
												</label>
												<label className="black-text-01" htmlFor="password">
													{Tools.convertString(s.crearcuenta?.password2)}
													<input
														className="input lightgray-bg-01 black-text-03"
														placeholder={Tools.convertString(s.crearcuenta?.password2_placeholder)}
														type="password"
														onChange={(e) => setPassword2(e.target.value)}
														value={password2}
													/>
												</label>
											</fieldset>
											<div className="button-wrapper lightgray-bg-01">
												<button type="submit" className="button black-bg-03 white-text">
													{Tools.convertString(s.crearcuenta?.siguiente)}
												</button>
											</div>
										</form>
									</div>
								</article>
								<article className="form-img show-lg">
									<img src="/img/bg/register-01.jpg" alt={``} />
								</article>
							</div>
						)}
						{/* /STEP 01 */}

						{/* STEP 02 */}
						{registerPage === 2 && (
							<div className="steps-wrapper lightgray-bg-01">
								<article className="form-box box">
									<div className="wrapper">
										<h4 className="black-text-03 prequel-font">{Tools.convertString(s.crearcuenta?.vives_en)}</h4>
										<ul className="steps-bullets">
											<li>
												<span className="lightgray-bg-03 white-text seen"></span>
											</li>
											<li>
												<span className="lightgray-bg-03 white-text active"></span>
											</li>
											<li>
												<span className="lightgray-bg-03 white-text"></span>
											</li>
											<li>
												<span className="lightgray-bg-03 white-text"></span>
											</li>
										</ul>
										<form onSubmit={handleSubmit}>
											<fieldset>
												<label className="black-text-01">
													{Tools.convertString(s.crearcuenta?.pais)}
													<Select
														classNamePrefix="Select"
														options={paises}
														onChange={handlePaisChange}
														value={pais}
														components={{ IndicatorSeparator: () => null }}
													></Select>
												</label>
												<label className="black-text-01">
													{Tools.convertString(s.crearcuenta?.zona)}
													<Select
														classNamePrefix="Select"
														options={zonas}
														onChange={handleZonaChange}
														value={zona}
														components={{ IndicatorSeparator: () => null }}
													></Select>
												</label>
												<label className="black-text-01">
													{Tools.convertString(s.crearcuenta?.localizacion)}
													<Select
														classNamePrefix="Select"
														options={localizaciones}
														onChange={handleLocalizacionChange}
														value={localizacion}
														components={{ IndicatorSeparator: () => null }}
													></Select>
												</label>
											</fieldset>
											<div className="button-wrapper lightgray-bg-01">
												<button type="button" className="button black-bg-03 white-text" onClick={back}>
													{Tools.convertString(s.crearcuenta?.volver)}
												</button>
												<button type="submit" className="button black-bg-03 white-text">
													{Tools.convertString(s.crearcuenta?.siguiente)}
												</button>
											</div>
										</form>
									</div>
								</article>
								<article className="form-img show-lg">
									<img src="/img/bg/register-01.jpg" alt={``} />
								</article>
							</div>
						)}
						{/* /STEP 02 */}

						{/* STEP 03 */}
						{registerPage === 3 && (
							<div className="steps-wrapper lightgray-bg-01">
								<article className="form-box box">
									<div className="wrapper">
										<h4 className="black-text-03 prequel-font">{Tools.convertString(s.crearcuenta?.describete)}</h4>
										<ul className="steps-bullets">
											<li>
												<span className="lightgray-bg-03 white-text seen"></span>
											</li>
											<li>
												<span className="lightgray-bg-03 white-text seen"></span>
											</li>
											<li>
												<span className="lightgray-bg-03 white-text active"></span>
											</li>
											<li>
												<span className="lightgray-bg-03 white-text"></span>
											</li>
										</ul>
										<form onSubmit={handleSubmit}>
											<fieldset>
												<label className="black-text-01">
													{Tools.convertString(s.crearcuenta?.descripcion)}
													<textarea
														className="lightgray-bg-01 black-text-03"
														placeholder={Tools.convertString(s.crearcuenta?.descripcion_placeholder)}
														value={descripcion}
														onChange={(e) => setDescripcion(e.target.value)}
													></textarea>
													<div className="align-right">
														<small className="lightgray-text-05 align-right">
															Caracteres restantes: <b>{200 - descripcionCaracteres}</b>
														</small>
													</div>
												</label>
												<label className="black-text-01 file-upload">
													{Tools.convertString(s.crearcuenta?.imagen)}
													<span>
														{imagenSrc !== null && (
															<img
																className="profile-img"
																src={imagenSrc}
																alt={Tools.convertString(s.crearcuenta?.tu_imagen)}
															/>
														)}
														{imagenSrc === null && (
															<img
																className="profile-img"
																src="/img/upload-icon.svg"
																alt={Tools.convertString(s.crearcuenta?.tu_imagen)}
															/>
														)}
														<input
															type="file"
															className="file"
															size="29"
															length="4096"
															onChange={handleImagenChange}
														/>
													</span>
													<small className="black-text-03">{Tools.convertString(s.crearcuenta?.imagen_info)}</small>
													<input className="upload lightgray-bg-01" />
												</label>
											</fieldset>
											<div className="button-wrapper lightgray-bg-01">
												<button type="button" className="button black-bg-03 white-text" onClick={back}>
													{Tools.convertString(s.crearcuenta?.volver)}
												</button>
												<button type="submit" className="button black-bg-03 white-text">
													{Tools.convertString(s.crearcuenta?.siguiente)}
												</button>
											</div>
										</form>
									</div>
								</article>
								<article className="form-img show-lg">
									<img src="/img/bg/register-01.jpg" alt={``} />
								</article>
							</div>
						)}
						{/* /STEP 03 */}

						{/* STEP 04 */}
						{registerPage === 4 && (
							<div className="steps-wrapper lightgray-bg-01">
								<article className="form-box box">
									<div className="wrapper">
										<h4 className="black-text-03 prequel-font">{Tools.convertString(s.crearcuenta?.seguridad)}</h4>
										<ul className="steps-bullets">
											<li>
												<span className="lightgray-bg-03 white-text seen"></span>
											</li>
											<li>
												<span className="lightgray-bg-03 white-text seen"></span>
											</li>
											<li>
												<span className="lightgray-bg-03 white-text seen"></span>
											</li>
											<li>
												<span className="lightgray-bg-03 white-text active"></span>
											</li>
										</ul>
										<form onSubmit={handleSubmit}>
											<p>{Tools.convertString(s.crearcuenta?.seguridad_info)}</p>
											<fieldset>
												<label className="black-text-01">
													{Tools.convertString(s.crearcuenta?.dni)}
													<input
														className="input lightgray-bg-01 black-text-03"
														placeholder={Tools.convertString(s.crearcuenta?.dni_placeholder)}
														type="text"
														value={dni}
														onChange={(e) => setDNI(e.target.value)}
													/>
												</label>
												<label className="black-text-01">
													{Tools.convertString(s.crearcuenta?.telefono)}
													<input
														className="input lightgray-bg-01 black-text-03"
														placeholder={Tools.convertString(s.crearcuenta?.telefono_placeholder)}
														type="text"
														value={telefono}
														onChange={(e) => setTelefono(e.target.value)}
													/>
												</label>
											</fieldset>
											<span className="tooltip" onClick={appUIPopupTooltipShow}>
												<span className="tip white-text">?</span>
												<small className="black-text-03">
													{Tools.convertString(s.crearcuenta?.seguridad_aclaracion)}
												</small>
											</span>
											<div>
												<span style={{ fontSize: 14 }}>
													<input
														type="checkbox"
														id="accept"
														name="accept"
														style={{ width: "auto", display: "inline" }}
														onChange={(e) => setTerminos(e.target.checked)}
														value={terminos}
													/>
													<button
														type="button"
														onClick={() =>
															appUIPopupTextoShow({
																title: boot.terminos.title,
																body: boot.terminos.body,
																button: Tools.convertString(s.crearcuenta?.terminos_button),
															})
														}
														style={{
															background: "none",
															border: "none",
															borderBottom: "1px solid black",
															padding: 0,
															marginLeft: 10,
														}}
													>
														{Tools.convertString(s.crearcuenta?.terminos)}
													</button>
												</span>
											</div>
											<div className="button-wrapper lightgray-bg-01">
												<button type="button" className="button black-bg-03 white-text" onClick={back}>
													{Tools.convertString(s.crearcuenta?.volver)}
												</button>
												<button type="submit" className="button black-bg-03 white-text">
													{Tools.convertString(s.crearcuenta?.siguiente)}
												</button>
											</div>
										</form>
									</div>
								</article>
								<article className="form-img show-lg">
									<img src="/img/bg/register-01.jpg" alt={``} />
								</article>
							</div>
						)}
						{/* /STEP 04 */}
						<Footer />
					</main>
				</Layout>
			</BodyClassName>
		</>
	);
};

const mapStateToProps = (state) => {
	const { boot } = state.app;
	const { registerPage } = state.app.uiSubpages;
	const { registerReady } = state.app.events;
	return {
		boot,
		registerPage,
		registerReady,
		s: state.app.boot.strings,
	};
};

const mapDispatchToProps = {
	appUIPopupTooltipShow,
	appUIRegisterPageBack,
	appUIPopupTextoShow,
	authLocalRegisterValidateInit,
	authLocalRegisterInit,
};

export default connect(mapStateToProps, mapDispatchToProps)(CrearCuenta);
